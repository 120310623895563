/* src/AuctionDetail.css */

.auction-detail-container {
    padding: 20px;
    background-color: #f4f4f4;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
  }
  
  .auction-title {
    text-align: center;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .auction-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
  }
  
  .auction-image {
    flex: 1;
    max-width: 500px; /* Set a maximum width */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .auction-details {
    flex: 1;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
  }
  
  .auction-details p {
    margin: 10px 0;
    color: #555;
    line-height: 1.6;
    font-size: 1.1em;
  }
  
  .auction-details h3 {
    margin-top: 20px;
    color: #007bff;
  }
  
  .auction-details ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .auction-details ul li {
    margin: 5px 0;
    font-size: 1.1em;
  }
  
  .back-button {
    text-align: center;
    margin-top: 20px;
  }
  
  .back-button .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .back-button .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
    transform: translateY(-3px);
  }
  