.footer {
  
    padding: 20px 0;
    text-align: center;

    color: #333333;

    
  color: #005050; /* Teal color for a professional look */
  text-decoration: none;
 
  border-radius: 6px; /* Slightly more rounded corners */
  border: 2px solid #005050; /* Thicker border for better visibility */
  background: linear-gradient(45deg, #005050, #333333); /* Gradient background */
  color: #fff; /* White text color */
  font-family: 'Helvetica Neue', sans-serif; /* Clean and modern font */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #ecf0f1;
  }
  
  .footer-section p, .footer-section ul {
    font-size: 14px;
    line-height: 1.6;
    color: #bdc3c7;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-section .social-icons a {
    margin: 0 10px;
    color: #ecf0f1;
    font-size: 20px;
    text-decoration: none;
  }
  
  .footer-section .social-icons a:hover {
    color: #FFD166; /* Myk gul farge for hover */
  }
  
  .footer-bottom {
    background-color: #003d3d; /* Mørkere nyanse av Teal for bunnen */
    color: #bdc3c7;
    padding: 10px 0;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }
  