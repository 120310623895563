/* src/NewAuction.css */

.new-auction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f9;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  color: #005050; /* Teal color */
}

.auction-category-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-button {
  background-color: #005050; /* Teal color */
  color: white;
  padding: 15px 30px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.category-button:hover {
  background-color: #003838; /* Darker teal on hover */
  transform: scale(1.05);
}
