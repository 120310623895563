/* Container for the entire edit auction page */
.edit-auction-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Styling for the form and preview sections */
  .edit-auction-form, .auction-preview {
    flex: 1 1 48%;
    margin-bottom: 20px;
  }
  
  /* Heading styles */
  .edit-auction-form h1, .auction-preview h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
  }
  
  .form-group textarea {
    height: 100px;
    resize: vertical;
  }
  
  /* Styling for image previews */
  .image-preview {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-container {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .image-container img {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .image-container button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  /* Button styles */
  .btn {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  /* Styling for the auction preview section */
  .auction-preview {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .auction-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .auction-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .auction-images img {
    max-width: 200px;
    max-height: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .auction-details p {
    margin: 5px 0;
    color: #333;
  }
  
  .auction-details p strong {
    font-weight: bold;
    color: #000;
  }
  