/* src/LoginModal.css */
.login-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
 
  }
  
  .login-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .login-modal-content h2 {
    margin-bottom: 15px;
  }
  
  .login-modal-content p {
    margin-bottom: 20px;
  }
  
  .login-modal-button,
  .login-modal-close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
  }
  
  .login-modal-button {
    background-color: #005050; /* Teal color for a professional look */
    color: white;
  }
  
  .login-modal-button:hover {
    background-color: #4CAF50; /* Grønn farge */
  }
  
  .login-modal-close-button {
    background-color: #6c757d;
    color: white;
  }
  
  .login-modal-close-button:hover {
    background-color: #565e64;
  }
  .signup-modal-button {
    background-color: #005050; /* Teal color for a professional look */
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
  }
  
  .signup-modal-button:hover {
    background-color: #45a049; /* Mørkere grønn ved hover */
  }
  