/* src/account.css */

.account-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .account-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .account-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .account-container label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  .account-container input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .account-container button {
    padding: 10px 20px;
    background-color: #c70039; /* Red color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    align-self: center;
  }
  
  .account-container button:hover {
    background-color: #900c3f; /* Darker red color */
  }
  
  .account-container .message {
    text-align: center;
    margin-top: 20px;
    color: #28a745;
    font-size: 16px;
  }
  
  .account-container .back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .account-container .back-button {
    padding: 10px 20px;
    background-color: #6c757d; /* Gray color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .account-container .back-button:hover {
    background-color: #5a6268; /* Darker gray color */
  }
  