/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
}

/* Container for hele oppsummeringssiden */
.summary-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Tittel og informasjonsseksjon */
.summary-title {
    color: #005050;
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.8em;
}

.summary-info {
    text-align: center;
    font-size: 1em;
    margin-bottom: 20px;
    color: #555;
}

/* Seksjoner for forskjellige data */
.summary-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Overskrifter for hver seksjon */
.summary-section h3 {
    color: #005050;
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

/* Liste for bilinformasjon og utstyr */
.summary-list {
    list-style-type: none;
    padding-left: 0;
    font-size: 0.95em;
}

.summary-list li {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

/* Bilder */
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

/* Navigasjonsknapper */
.form-navigation {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 30px;
}

.summary-btn-primary,
.summary-btn-secondary {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 150px; /* Fast bredde for knappene */
    text-align: center;
}

/* Primærknapp (Send inn) */
.summary-btn-primary {
    background-color: #005050 !important;
    color: #fff !important;
    border: none !important;
}

.summary-btn-primary:hover {
    background-color: #003838 !important;
}

/* Sekundærknapp (Tilbake) */
.summary-btn-secondary {
    background-color: #f4f4f9 !important;
    color: #005050 !important;
    border: 1px solid #005050 !important;
}

.summary-btn-secondary:hover {
    background-color: #ddd !important;
}

/* Bekreftelsesmelding */
.confirmation-message {
    text-align: center;
    padding: 20px;
    background-color: #e6ffe6;
    border: 1px solid #00cc00;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
}

.confirmation-message h2 {
    color: #00cc00;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.confirmation-message p {
    color: #333;
    font-size: 1em;
}

.confirmation-message .btn {
    margin-top: 20px;
}

/* Responsive design for mindre skjermer */
@media (max-width: 768px) {
    .summary-container {
        width: 90%;
        padding: 15px;
    }

    .image-preview-container {
        justify-content: center;
    }

    .image-preview {
        width: 80px;
        height: 80px;
    }

    .summary-btn-primary,
    .summary-btn-secondary {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .summary-container {
        width: 95%;
        padding: 10px;
    }

    .image-preview {
        width: 70px;
        height: 70px;
    }

    .summary-title {
        font-size: 1.5em;
    }

    .form-navigation {
        flex-direction: column;
    }
}
