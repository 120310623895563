/* Generelle stiler for body */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
  }
  
  /* Container for hele innloggingsboksen */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eef2f7;
    padding: 20px;
  }
  
  /* Stil for innloggingsboksen */
  .login-box {
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  /* Fjerner standard lenkestil for knapper med lenker */
.login-link {
    color: inherit;           /* Arver fargen fra knappen */
    text-decoration: none;    /* Fjerner understrekning */
    display: block;           /* Gjør lenken til et blokk-element for å fylle hele knappen */
    width: 100%;              /* Fyller hele knappens bredde */
    height: 100%;             /* Fyller hele knappens høyde */
    text-align: center;       /* Sentraliserer teksten */
  }
  
  /* Hover-effekt for login-boksen */
  .login-box:hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  /* Overskrift */
  .login-box h2 {
    margin-bottom: 24px;
    font-size: 1.8em;
    color: #333;
    text-align: center;
  }
  
  /* Skjema-grupper */
  .login-form-group {
    margin-bottom: 20px;
  }
  
  /* Skjema-label */
  .login-form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
  }
  
  /* Skjema-input */
  .login-form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 1em;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Fokus-effekt for input */
  .login-form-control:focus {
    border-color: #999;
    box-shadow: 0 0 5px rgba(153, 153, 153, 0.3);
    outline: none;
  }
  
  /* Stil for input-gruppen med passordfelt og øye-ikon */
  .login-input-group {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* Passordfeltet skal fylle bredden */
  .login-input-group .login-form-control {
    flex: 1;
    padding-right: 40px; /* Gjør plass til øye-ikonet */
  }
  
  /* Stil for øye-knappen */
  .login-input-group .login-btn-eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #777;
    cursor: pointer;
    padding: 0;
    font-size: 1.2em;
  }
  
  /* Hover-effekt for øye-knappen */
  .login-input-group .login-btn-eye:hover {
    color: #555;
  }
  
  /* Stil for husk meg-feltet */
  .login-form-check {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  /* Checkbox-stil */
  .login-form-check-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
 
  /* Label for husk meg */
  .login-form-check-label {
    font-weight: 500;
    color: #555;
    cursor: pointer;
  }
  
  /* Knappestil */
  .login-btn {
    padding: 12px 20px;
    border-radius: 6px;
    font-size: 1em;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Primærknapp (Logg inn) */
  .login-btn-success {
    background-color: #4caf50;
    border: none;
    color: white;
  }
  
  .login-btn-success:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }
  
  /* Sekundærknapper (Opprett konto, Glemt passord) */
  .login-btn-default {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    color: #555;
    margin-top: 10px;
  }
  
  .login-btn-default:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
  }
  
  /* Full bredde for knapper */
  .login-w-100 {
    width: 100%;
  }
  
  /* Tekst for vilkår og betingelser */
  .terms-text {
    margin-top: 16px;
    font-size: 0.9em;
    text-align: center;
    color: #777;
  }
  /* Stil for sekundære handlingsknapper (Opprett konto og Glemt passord) */
/* Sørg for at sekundære knapper tar full bredde */
.login-btn-secondary {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    color: #555;
    font-weight: 600;
    padding: 12px 20px;
    border-radius: 6px;
    width: 100%;           /* Sørger for full bredde */
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
    margin-bottom: 15px;   /* Øker avstanden til hjelpeteksten under */
  }
  
  /* Hover-effekt for sekundære knapper */
  .login-btn-secondary:hover {
    background-color: #e0e0e0;
    color: #333;
    transform: translateY(-2px);
    border-color: #bbb;
  }
  
  /* Juster hjelpetekstens avstand */
  .login-helper-text {
    font-size: 0.9em;
    color: #777;
    margin-top: 5px;
    margin-bottom: 20px;   /* Øker avstanden til neste element */
    text-align: center;
  }
  