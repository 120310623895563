/* Container for hele dashboardet */
.admin-dashboard-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
/* Spinner-stiler */
.spinner-container-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Juster etter behov */
}

.spinner-admin {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3; /* Lys grå */
  border-top: 6px solid #005050; /* Hovedfargen din */
  border-radius: 50%;
  animation: spin-admin 1s linear infinite;
}

@keyframes spin-admin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Fade-in-effekt */
.fade-in-admin {
  animation: fadeIn-admin 0.5s ease-in-out;
}

@keyframes fadeIn-admin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Header styling */
.admin-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(135deg, #007070, #003838); /* Gradient i nyanser av #005050 */
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.admin-dashboard-header h1 {
  font-size: 26px;
  margin: 0;
  font-weight: bold;
}

/* Seksjoner */
.admin-dashboard-sections {
  display: grid;
  grid-template-columns: 1fr 1fr; /* To kolonner */
  gap: 20px;
}

.admin-dashboard-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-top: 4px solid #005050;
}


.admin-dashboard-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.admin-dashboard-section h2 {
  font-size: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 15px;
  color: #333;
}

/* Liste av auksjoner */
.admin-auction-list,
.admin-live-auction-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Kort for hver auksjon */
.admin-auction-item,
.admin-live-auction-item {
  background-color: #f8f9fa;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.admin-auction-item:hover,
.admin-live-auction-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Detaljer og handlinger */
.admin-auction-details,
.admin-live-auction-details {
  margin-bottom: 12px;
  font-size: 14px;
  color: #555;
}

.admin-auction-details p,
.admin-live-auction-details p {
  margin: 8px 0;
}

/* Knappestiler */
.admin-auction-actions,
.admin-live-auction-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-btn {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.admin-btn admin-btn-secondary{
  background-color: black;
}
.admin-btn:hover {
  transform: translateY(-2px);
}

.admin-btn-danger {
  background-color: #e74c3c;
}

.admin-btn-danger:hover {
  background-color: #c0392b;
}

.admin-btn-success {
  background-color: #2ecc71;
}

.admin-btn-success:hover {
  background-color: #27ae60;
}

.admin-btn-info {
  background-color: #3498db;
}

.admin-btn-info:hover {
  background-color: #2980b9;
}

/* Bilder og bildegalleri */
.admin-auction-carousel {
  position: relative;
  width: 100%;
  max-width: 220px;
  margin: 0 auto 12px;
}

.admin-auction-image,
.admin-live-auction-image {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
  border: 2px solid #4facfe;
}

/* Pilknapper for bildekarusell */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 6px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
}

.carousel-control.prev {
  left: 5px;
}

.carousel-control.next {
  right: 5px;
}

.carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsivitet */
@media (max-width: 768px) {
  .admin-dashboard-sections {
    grid-template-columns: 1fr; /* Én kolonne på mindre skjermer */
  }

  .admin-dashboard-header {
    flex-direction: column;
    gap: 10px;
  }

  .admin-auction-actions,
  .admin-live-auction-actions {
    flex-direction: column;
  }

  .admin-btn {
    width: 100%;
    text-align: center;
  }
}
