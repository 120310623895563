/* Spesifikke selektorer for EditLiveAuction */
.edit-live-auction {
    max-width: 900px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.edit-live-auction h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    color: #333;
    font-weight: 600;
}

.edit-live-auction .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.edit-live-auction .form-group label {
    margin-bottom: 6px;
    font-size: 14px;
    color: #555;
    font-weight: 500;
}

.edit-live-auction .form-group input,
.edit-live-auction .form-group select,
.edit-live-auction .form-group textarea {
    padding: 10px 12px;
    font-size: 14px;
    color: #333;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.edit-live-auction .form-group input:focus,
.edit-live-auction .form-group select:focus,
.edit-live-auction .form-group textarea:focus {
    border-color: #3f51b5;
    box-shadow: 0 0 0 3px rgba(63, 81, 181, 0.1);
    outline: none;
    background-color: #fff;
}

.edit-live-auction .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.edit-live-auction .form-row .form-group {
    flex: 1 1 45%;
}

.edit-live-auction .form-row .form-group.full-width {
    flex: 1 1 100%;
}

.edit-live-auction .button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.edit-live-auction .button-group button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #3f51b5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-live-auction .button-group button:hover {
    background-color: #303f9f;
}

.edit-live-auction .image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.edit-live-auction .image-container {
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.edit-live-auction .image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.edit-live-auction .image-container .remove-image {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    background-color: rgba(0,0,0,0.6);
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
}

.edit-live-auction .image-container:hover .remove-image {
    opacity: 1;
}

@media (max-width: 600px) {
    .edit-live-auction .form-row .form-group {
        flex: 1 1 100%;
    }
    
    .edit-live-auction .button-group {
        justify-content: center;
    }
}
