/* src/SearchResults.css */

.search-results-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .search-results-container h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .search-results-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .search-result-item {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    flex: 0 0 auto;
  }
  
  .search-result-item:hover {
    transform: scale(1.05);
  }
  
  .search-result-image {
    max-width: 100%;
    height: 150px;
    border-radius: 10px;
    margin-bottom: 15px;
    object-fit: cover;
  }
  
  .search-result-details {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .search-result-details h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .search-result-details p {
    margin-bottom: 8px;
    font-size: 0.9em;
    color: #666;
  }
  